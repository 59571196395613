import { AutocompleteRecord } from '@utils/interfaces';

import { api_react_v1_article_path, api_react_v1_autocomplete_articles_path } from '../../routes';
import request from './request';
import { getRecord } from './universal';

export interface User {
  email: string;
  id: number;
  identityId: number;
  name: string;
}

export interface Article {
  activeOnly: boolean;
  author: User;
  authorsAsSentence: string;
  id: number;
  emailSubject: string;
  newLeads: User[];
  newSubscribers: User[];
  viewers: User[];
  publishedAt: string;
  publishOnWeb: boolean;
  rtsuSentAt: string;
  sendRtsu: boolean;
  sendRtsuAt: string;
  seoTitle?: string;
  shareUrl: string;
  slug: string;
  title: string;
  tagNames: string[];
  urlShortcode: string;
  unlockViaReferralUrl: boolean;
}

export async function autocompleteArticles(fragment: string): Promise<AutocompleteRecord[]> {
  const resp = await request('get', api_react_v1_autocomplete_articles_path(), { fragment });
  return resp.articles;
}

export async function getArticle(id: number | string): Promise<Article> {
  const resp = await getRecord({
    id,
    model: 'Article',
    attributes: [
      'activeOnly',
      'publishedAt',
      'publishOnWeb',
      'title',
      'emailSubject',
      'rtsuSentAt',
      'sendRtsu',
      'sendRtsuAt',
      'seoTitle',
      'slug',
      'urlShortcode',
      'unlockViaReferralUrl',
    ],
    helpers: ['shareUrl', 'authorsAsSentence', 'tagNames'],
    relations: {
      newSubscribers: { attributes: ['name', 'email', 'id', 'identity_id'] },
      newLeads: { attributes: ['name', 'email', 'id', 'identity_id'] },
      viewers: { attributes: ['name', 'email', 'id', 'identity_id'] },
    },
  });
  return resp.result;
}

export async function updateArticle(id: number | string, values: Partial<Article>) {
  const resp = await request('patch', api_react_v1_article_path(id), {
    article: values,
  });
  return resp;
}
