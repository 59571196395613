import { api_react_v1_autocomplete_entities_path, api_react_v1_entities_path } from '../../routes';
import { AutocompleteRecord } from '../interfaces';
import request from './request';
import { getRecords } from './universal';

export interface AutocompleteParams {
  fragment: string;
  entity_type?: string;
  word_type?: string;
  relationship_type?: string;
  order_entities_by?: string[];
  relationship_sources?: string[];
}

export const ENTITY_TYPE_GENDER = 'gender';
export const ENTITY_TYPE_JOB_ROLE = 'job_role';
export const ENTITY_TYPE_JOB_TITLE = 'job_title';
export const ENTITY_TYPE_JOB_LEVEL = 'job_level';
export const ENTITY_TYPE_LOCATION = 'location';
export const ENTITY_TYPE_ORGANIZATION = 'organization';
export const ENTITY_TYPE_ORGANIZATION_INDUSTRY = 'organization_industry';

export const WORD_TYPE_COMMON = 'common';
export const WORD_TYPE_PROPER = 'proper';

export interface EntityJoin {
  id: number;
  entity_id: number;
  entity?: Entity;
}

export interface Entity {
  id: number;
  name: string;
}

export interface EntityResponse {
  id: number;
  success: boolean;
  name: string;
  new_record: boolean;
}

export async function autocompleteEntities(
  values: AutocompleteParams
): Promise<AutocompleteRecord[]> {
  const resp = await request('get', api_react_v1_autocomplete_entities_path(values));
  return resp.entities;
}

export interface SanitizedEntityParams {
  id?: number;
  name: string;
  entity_type: string;
  word_type: string;
}

export async function createEntity(values: SanitizedEntityParams): Promise<EntityResponse> {
  const resp = await request('post', api_react_v1_entities_path(), { entity: values });
  return resp;
}

export const getArticleEntities = async (articleId: number | string): Promise<EntityJoin[]> => {
  const resp = await getRecords({
    model: 'EntityJoin',
    attributes: ['id'],
    identifiers: { parentType: 'Article', parentId: articleId },
    relations: { entity: { attributes: ['id', 'name'] } },
    comparisonIdentifiers: { salience: { comparison: '>=', value: '0.5' } },
  });
  return resp.result;
};

export const getImportantEntities = async (): Promise<Entity[]> => {
  const resp = await getRecords({
    model: 'Entity',
    attributes: ['id', 'name'],
    identifiers: { isImportant: true },
  });
  return resp.result;
};
