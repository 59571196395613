import { useQuery } from 'react-query';

import { getImportantEntities } from '../utils/backend-api/entities';

const ENTITIES_WITH_IS_IMPORTANT_KEY = 'EntitiesWithIsImportant';
export default function useEntitiesIsImportant() {
  const {
    data: entities,
    isLoading,
    isError,
  } = useQuery(ENTITIES_WITH_IS_IMPORTANT_KEY, () => getImportantEntities());
  const importantEntities = entities?.map((entity) => ({
    label: entity.name,
    value: entity.id,
  }));

  return { importantEntities, isLoading, isError };
}
