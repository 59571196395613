import React, { useState } from 'react';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import closeIcon from '../../../images/x_icon.svg';

import useArticle, { ARTICLE_KEY } from '../../../hooks/useArticle';
import useEntityJoinDelete from '../../../hooks/useEntityJoinDelete';
import { updateArticle } from '../../../utils/backend-api/articles';
import { capitalize } from '../../../utils/helpers';
import Pill from '../../BaseUI/Pill';
import EmailModal from '../../Crm/EmailModal';
import IdentityModal from '../../Crm/IdentityModal';
import CreateTagForm from './CreateTagForm';
import RecipientTable from './RecipientTable';
import styles from './styles.module.css';

const ArticleDashboard = () => {
  const [showIdentityModal, setShowIdentityModal] = useState<number>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emailModalIdentities, setEmailModalIdentities] = useState(null);
  const { articleId } = useParams();
  const { article, isLoadingArticle } = useArticle(articleId);
  const queryClient = useQueryClient();

  const { mutate: deleteEntityJoin } = useEntityJoinDelete();

  const handleDeleteEntityJoin = async (name) => {
    const sanitizedParams = {
      name: name,
      parentType: 'Article',
      parentId: parseInt(articleId),
    };
    await deleteEntityJoin(sanitizedParams);
  };

  const handleUpdateArticle = async (params) => {
    setIsSubmitting(true);
    try {
      const response = await updateArticle(articleId, params);
      if (response) {
        toast.success('Article updated');
        queryClient.invalidateQueries([ARTICLE_KEY, articleId]);
      } else {
        toast.error('Failed to update article');
      }
    } catch (e) {
      toast.error(`Error occurred while updating article ${e}`);
    }
    setIsSubmitting(false);
  };

  const setEmailModalUsers = (users) => {
    setEmailModalIdentities(users.map((user) => ({ email: user.email, id: user.identityId })));
  };

  return (
    <div className="container-fluid">
      {isLoadingArticle ? (
        'Loading...'
      ) : (
        <>
          {showIdentityModal && (
            <IdentityModal
              setShowIdentityModal={setShowIdentityModal}
              identityId={showIdentityModal}
            />
          )}
          {emailModalIdentities && (
            <EmailModal
              identities={emailModalIdentities}
              hideModal={() => setEmailModalIdentities(null)}
            />
          )}
          <Row>
            <Col className={styles.fieldName} md={2}>
              Free Link
            </Col>
            <Col md={6} className={styles.fieldValue}>
              {article.urlShortcode
                ? `https://theinformation.com/go/${article.urlShortcode}`
                : 'unavailable'}
            </Col>
            <Col md={4}>
              <input
                className={styles.readonlyCheckbox}
                type="checkbox"
                checked={article.activeOnly}
                disabled={isSubmitting}
                onChange={() =>
                  handleUpdateArticle({
                    active_only: !article.activeOnly,
                  })
                }
              />
              <span className={styles.fieldName}>Hard Paywall</span>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className={styles.fieldName} md={2}>
              Paywall Link
            </Col>
            <Col md={6} className={styles.fieldValue}>
              {article.shareUrl}
            </Col>
            <Col md={4}>
              <div>
                <input
                  className={styles.readonlyCheckbox}
                  type="checkbox"
                  checked={article.unlockViaReferralUrl}
                  disabled={isSubmitting}
                  onChange={() =>
                    handleUpdateArticle({
                      unlock_via_referral_url: !article.unlockViaReferralUrl,
                    })
                  }
                />
                <span className={styles.fieldName}>Unlock Via Referral</span>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className={styles.fieldName} md={2}>
              Tags
            </Col>
            <Col md={6}>
              <CreateTagForm articleId={articleId} />
              {article.tagNames.length == 0 ? (
                <span className={styles.fieldValue}>No tags</span>
              ) : (
                article.tagNames.map((tagName) => (
                  <Pill
                    onClick={() => handleDeleteEntityJoin(tagName)}
                    className="--neutral"
                    text={capitalize(tagName)}
                    icon={closeIcon}
                    key={tagName}
                  />
                ))
              )}
            </Col>
          </Row>

          <Tabs defaultActiveKey="newSubscribers" id="recipients" className="mt-5">
            <Tab
              eventKey="newSubscribers"
              title={`New Subscribers (${article.newSubscribers.length})`}
            >
              <div id="new-subscribers" className="mt-4">
                <RecipientTable
                  articleId={articleId}
                  recipientType="new_subscribers"
                  showIdentityModal={setShowIdentityModal}
                  emailModalUsers={() => setEmailModalUsers(article.newSubscribers)}
                />
              </div>
            </Tab>
            <Tab eventKey="newLeads" title={`New Leads (${article.newLeads.length})`}>
              <div id="new-leads" className="mt-4">
                <RecipientTable
                  articleId={articleId}
                  recipientType="new_leads"
                  showIdentityModal={setShowIdentityModal}
                  emailModalUsers={() => setEmailModalUsers(article.newLeads)}
                />
              </div>
            </Tab>
            <Tab eventKey="viewers" title={`Viewers (${article.viewers.length})`}>
              <div id="viewers" className="mt-4">
                <RecipientTable
                  articleId={articleId}
                  recipientType="viewers"
                  showIdentityModal={setShowIdentityModal}
                  emailModalUsers={() => setEmailModalUsers(article.viewers)}
                />
              </div>
            </Tab>
          </Tabs>
        </>
      )}
    </div>
  );
};

export default ArticleDashboard;
