import { Formik } from 'formik';
import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';

import { READER_URL } from '@src/env';
import { autocompleteEntities } from '@utils/backend-api/entities';

import {
  useAdSponsorOptions,
  useAdministratorLists,
  useAdvertisement,
  useEntitiesIsImportant,
} from '../../../hooks';
import {
  AdvertisementFormValues,
  AdvertisementSanitizedValues,
  SendTestAdvertisementParams,
  sendTestAdvertisement,
  updateAdvertisement,
} from '../../../utils/backend-api/advertisements';
import { SendTestForm } from '../../BaseUI';
import {
  AutocompleteField,
  ImageField,
  MultiValueAutocompleteField,
  TextAreaField,
  TextField,
} from '../../FormFields';
import './AdvertisementForm.scss';
import advertisementSchema from './advertisementSchema';

export const INITIAL_STATE_AD = {
  clickthroughUrl: '',
  entities: [],
  excludeList: { label: '', value: 0 },
  externalImageUrl: '',
  header: '',
  image: '',
  shortHeader: '',
  sponsorLogo: '',
  sponsorName: { label: '', value: '' },
  sponsorNameMessageHead: '',
  sponsorNameMessageBody: '',
  text: '',
  trackingPixelUrl: '',
};

interface AdvertisementFormProps {
  articlePreviewUrl?: string;
  formValues?: AdvertisementFormValues;
  isNewAd?: boolean;
  scheduling?: boolean;
}

const SUPPORTED_BY_FALLBACK_IMAGE = 'https://placehold.co/100x50/png';

const generateSponsorTextHint = (sponsorName: string, defaultPrefix: string) => {
  const sponsorNameDisplay = sponsorName || 'SPONSOR_NAME';
  return `e.g., "Sponsored by ${sponsorNameDisplay}". If left blank, defaults to "${defaultPrefix} ${sponsorNameDisplay}"`;
};

/**
 * AdvertisementForm: Shared form for creating or editing an advertisement and its schedule.
 * Creating / editing schedules for an add is only available once add exists,
 * therfore the ScheduleFormSection component is only accessible for the edit page.
 *
 * Utilized by NewAdvertisement and EditAdvertisement.
 */
const AdvertisementForm = ({
  articlePreviewUrl,
  formValues = INITIAL_STATE_AD,
  isNewAd,
  scheduling,
}: AdvertisementFormProps) => {
  const { id: advertisementId } = useParams();
  const { administratorListOptions } = useAdministratorLists();
  const { adSponsorOptions } = useAdSponsorOptions();
  const { refetch: refetchAdvertisement } = useAdvertisement(advertisementId);
  const [supportedByLogo, setSupportedByLogo] = useState(null);
  const { importantEntities } = useEntitiesIsImportant();

  const navigate = useNavigate();

  const handleSubmitAdvertisement = async (values: AdvertisementFormValues) => {
    const { entities, excludeList, image, sponsorName, sponsorLogo, ...restVals } = values;
    const sanitizedParams: AdvertisementSanitizedValues = {
      ...restVals,
      entities: entities.map((entity) => entity.value),
      excludedListId: excludeList.value || '',
      image: image || '',
      sponsorLogo: sponsorLogo || 'deleted',
      sponsorName: sponsorName.label,
    };

    // create FormData object so we can send request with content-type form-data for image uploading
    const formData = new FormData();
    for (const key in sanitizedParams) {
      if (
        (key === 'image' ||
          (key === 'sponsorLogo' && sanitizedParams['sponsorLogo'] != 'deleted')) &&
        typeof sanitizedParams[key] === 'string'
      ) {
        // if image is a string, it's a URL and we don't need to send it
        continue;
      }
      formData.append(key, sanitizedParams[key]);
    }
    // send id if updating existing ad
    if (advertisementId) formData.append('advertisementId', advertisementId);
    try {
      const resp = await updateAdvertisement(formData);
      if (resp.message === 'ok') {
        // if we're creating a new ad, redirect to edit page
        if (resp.redirect_url) {
          toast.success('Advertisement created!');
          navigate(resp.redirect_url);
        } else {
          toast.success('Advertisement updated!');
          refetchAdvertisement();
        }
      }
    } catch (error) {
      toast.error(error || 'Sorry, there was a problem');
    }
  };

  const handleAdTestEmail = async (sanitizedParams: SendTestAdvertisementParams) => {
    try {
      const resp = await sendTestAdvertisement(sanitizedParams);
      if (resp.message === 'ok') {
        toast.success('Sent Test Email!');
      }
    } catch (error) {
      toast.error(error || 'Sorry, there was a problem');
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formValues}
      onSubmit={handleSubmitAdvertisement}
      validationSchema={advertisementSchema}
    >
      {(formik) => (
        <Form className="AdvertisementForm" noValidate onSubmit={formik.handleSubmit}>
          <Row>
            <Col>
              <AutocompleteField
                isDisabled={scheduling}
                label="Sponsor"
                name="sponsorName"
                options={adSponsorOptions}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                disabled={scheduling}
                label="Message in email head for sponsor name"
                hint={generateSponsorTextHint(formik.values.sponsorName.label, 'Supported by')}
                name="sponsorNameMessageHead"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <ImageField
                name="sponsorLogo"
                disabled={scheduling}
                label="Sponsor logo"
                width="100px"
                height="50px"
                imgBtnLabel="Upload Sponsor Logo"
                hint="(Should be at least 50px tall for email. Others will be resized to that height.)"
                setImageSrc={setSupportedByLogo}
              />

              <div>
                <p className="supported-by-disclaimer">
                  Logo preview for top of emails:
                  <span>
                    <em>Note:</em> If the logo appears off center or falls to two lines, you should
                    edit the image so it appears correctly. Image size needs to be at least 50px
                    tall for the email and positioned in the center of that vertical space. Reach
                    out to a designer for help, if needed. If an image is still too wide, give it
                    more vertical empty space in image editing software.
                  </span>
                </p>
                <div className="center supported-by">
                  <span>
                    <span>Supported by</span>
                    <img
                      src={supportedByLogo || SUPPORTED_BY_FALLBACK_IMAGE}
                      height={50}
                      width="auto"
                    />
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                disabled={scheduling}
                label="Message in email body for sponsor name"
                hint={generateSponsorTextHint(formik.values.sponsorName.label, 'A Message From')}
                name="sponsorNameMessageBody"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField disabled={scheduling} name="header" label="Header" />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                disabled={scheduling}
                name="shortHeader"
                label="Short header"
                hint="Replaces 'Header' text on mobile for all on-site banner ad units"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <ImageField
                name="image"
                disabled={scheduling || !!formik.values.externalImageUrl}
                label="Image"
                width={285}
                height={235}
                hint="(Should be 570x470. Others will be resized to that width.)"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                name="externalImageUrl"
                label="External Image URL"
                disabled={scheduling || !!formik.values.image}
                helper="Upload an image or enter an external image URL"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                name="trackingPixelUrl"
                label="Tracking Pixel URL"
                disabled={scheduling}
                helper="Use [timestamp] insert a timestamp in the url. (e.g., http://www.example.com?timestamp=[timestamp] will be converted to http://www.example.com?timestamp=1667489179)"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextAreaField disabled={scheduling} name="text" label="Text" minRows={4} />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField disabled={scheduling} name="clickthroughUrl" label="URL" />
            </Col>
          </Row>
          <Row>
            <Col>
              <AutocompleteField
                isCreatable={false}
                isDisabled={scheduling}
                label="Email exclusion list (optional, leave blank to exclude none)"
                name="excludeList"
                options={administratorListOptions}
                valueIsInteger
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <MultiValueAutocompleteField
                isDisabled={scheduling}
                label="Associated topics (for onsite placement)"
                name="entities"
                options={importantEntities}
                placeholder="Find a topic..."
                fetchMethod={(value) =>
                  autocompleteEntities({
                    fragment: value,
                  })
                }
              />
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-end">
              <Button
                disabled={!formik.dirty || scheduling}
                type="submit"
                className="continue-button button --black"
                id="send"
              >
                Save
              </Button>
            </Col>
          </Row>
          {!isNewAd && (
            <>
              <hr className="my-4" />
              <Row>
                <SendTestForm
                  testMethod={handleAdTestEmail}
                  includedFields={[
                    {
                      sanitizedName: 'advertisement_id',
                      value: advertisementId,
                    },
                  ]}
                />
              </Row>
              <Row>
                <Col xs={3}>
                  <Button
                    variant="outline-dark"
                    href={`${READER_URL}?ad_preview=${advertisementId}`}
                    target="_blank"
                  >
                    Preview on homepage
                  </Button>
                </Col>
                <Col xs={3}>
                  <Button variant="outline-dark" href={articlePreviewUrl} target="_blank">
                    Preview on article
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default AdvertisementForm;
